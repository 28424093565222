.footer {
  position: relative;

  @media (max-width: 767px) {
    text-align: center;
  }
}

.footerImage {
  display: block;
  margin: 0 auto -7vw;

  @media (min-width: 1400px) {
    margin-bottom: -100px;
  }
}

.footerImageFrontpage {
  display: block;
  margin: 0 auto -8vw;
  z-index: 1;
  position: relative;
  left: 5%;
  margin-top: -30vw;
  pointer-events: none;
  width: 1000px;
  max-width: 90%;

  @media (min-width: 1350px) {
    margin-top: -500px;
    margin-bottom: 0;
    bottom: -100px;
  }

  @media (max-width: 767px) {
    margin-top: -5vw;
    left: 0;
    width: 100%;
  }

  @media (max-width: 500px) {
    margin-top: -10vw;
  }
}

.some a {
  margin-right: var(--spacing-4);

  &:hover {
    opacity: 0.8;
  }
}

.textFornebu {
  color: var(--color-cream-40);
}
